import { SendRequestMessageData } from '../parentApp/types/messages';
import { APIBaseService } from './base.api';
import {
  FormsGetFormTokenResponse,
  FormsLoadClientResponse,
  FormsSubmitClientRequest,
} from './types/forms.responses';
import ParentAppService from '../parentApp';

export class APIFormsService extends APIBaseService {
  static submit(body: FormsSubmitClientRequest, uuidKey?: string): string {
    const uuid = uuidKey || ParentAppService.generateUUID();
    const url = new URL(`${APIBaseService.apiUrl}/api/forms/submit`).href;
    const data: SendRequestMessageData = {
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      url,
      uuid,
    };
    ParentAppService.sendRequestMessage(data);
    return uuid;
  }

  static async loadClient(formToken: string): Promise<FormsLoadClientResponse> {
    const url = new URL(
      `${APIBaseService.apiUrl}/api/forms/load-client?form=${formToken}`,
    ).href;
    const res = await fetch(url, {
      method: 'GET',
    });
    const resD = (await res.json()) as FormsLoadClientResponse;
    return resD;
  }

  static async getTokenFromSlug(slug: string): Promise<string | null> {
    const url = new URL(
      `${APIBaseService.apiUrl}/api/forms/get-form-token?slug=${slug}`,
    ).href;
    const res = await fetch(url, {
      method: 'GET',
    });
    const response = (await res.json()) as FormsGetFormTokenResponse;
    return response.data.token;
  }
}
