import { Dispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  actionGiveConsent,
  actionLoadForm,
  actionRemoveConsent,
} from '../../store/actions/form.actions';
import { actionLoadResponse } from '../../store/actions/responses.actions';
import { actionUIOpen } from '../../store/actions/ui.actions';
import {
  ParentAppMessage,
  ParentAppMessageCommand,
  WidgetAppMessageCommand,
  WidgetAppMessage,
} from './types/index.d';
import {
  IncomingLoadFormMessage,
  IncomingOpenMessage,
  IncomingResponseMessage,
  SendCloseMessageData,
  SendExitedMessageData,
  SendInitMessageData,
  SendMessagePayload,
  SendRequestMessageData,
} from './types/messages';
import uuidv4 from 'uuid-browser';
import APIService from '../api/index.api';
import { APIBaseService } from '../api/base.api';
import { StatsType } from '../api/types/stats.response.d';
import { checkForTapformDemoSubmissionParam } from '../../helpers';

class ParentAppService {
  private static formToken: string | null = null;

  static async windowEventListener(msg: MessageEvent<any>, dispatch: Dispatch) {
    const messageData: ParentAppMessage | WidgetAppMessage = msg.data;
    if (
      messageData.key &&
      messageData.key === 'tapform-app' &&
      messageData.command &&
      messageData.data
    ) {
      switch (messageData.command) {
        case ParentAppMessageCommand.LOAD_FORM: {
          const messageDataParsed = messageData as IncomingLoadFormMessage;
          APIService.apiUrl = messageDataParsed.data.apiUrl;
          APIBaseService.apiUrl = messageDataParsed.data.apiUrl;
          APIService.formsService.apiUrl = messageDataParsed.data.apiUrl;
          APIService.imagesService.apiUrl = messageDataParsed.data.apiUrl;
          APIService.statsService.apiUrl = messageDataParsed.data.apiUrl;
          return dispatch(
            actionLoadForm({
              data: messageDataParsed.data.configuration.data,
            }),
          );
        }

        case WidgetAppMessageCommand.SEND: {
          if (this.formToken) {
            const sendData = messageData.data as SendRequestMessageData;
            const response = await fetch(sendData.url, {
              ...sendData.options,
              headers: {
                ...sendData.options.headers,
                'tapform-token': this.formToken,
              },
            });
            const body = await response.json();
            const msgData: IncomingResponseMessage = {
              key: 'tapform-app',
              command: ParentAppMessageCommand.RESPONSE,
              data: {
                key: sendData.uuid,
                body,
                status: response.status,
              },
            };
            ParentAppService.sendMessage(msgData);
          }
          return true;
          // return dispatch(actionLoadResponse());
        }

        case WidgetAppMessageCommand.INIT: {
          const formSlug = this.checkIfFormLink();
          if (
            formSlug &&
            (!this.checkIfInsideWebsite() ||
              formSlug === process.env.REACT_APP_FORM_SLUG_FOR_LANDING)
          ) {
            try {
              const formToken = await APIService.formsService.getTokenFromSlug(
                formSlug,
              );
              if (formToken) {
                const configuration = await APIService.formsService.loadClient(
                  formToken,
                );
                if (configuration) {
                  this.formToken = formToken;
                  dispatch(
                    actionLoadForm({
                      data: configuration.data,
                    }),
                  );
                  setTimeout(() => {
                    document.title = configuration.data.widgetTitle;
                    dispatch(actionUIOpen({ isFromLink: true }));
                    if (!checkForTapformDemoSubmissionParam()) {
                      APIService.statsService.report({
                        type: StatsType.OPEN_FROM_LINK,
                        clientKey: configuration.data.clientKey,
                      });
                    }
                  }, 0);
                }
                return;
              }
            } catch (e) {
              return;
            }
          }
          return;
          // return dispatch(actionLoadResponse());
        }

        case ParentAppMessageCommand.OPEN: {
          const messageDataParsed = messageData as IncomingOpenMessage;
          return dispatch(
            actionUIOpen({
              isFixed: messageDataParsed.data?.isFixed,
              isFromLink: false,
            }),
          );
        }

        case ParentAppMessageCommand.GIVE_CONSENT: {
          return dispatch(actionGiveConsent());
        }

        case ParentAppMessageCommand.REMOVE_CONSENT: {
          return dispatch(actionRemoveConsent());
        }

        case ParentAppMessageCommand.RESPONSE: {
          const messageDataParsed = messageData as IncomingResponseMessage;
          return dispatch(actionLoadResponse(messageDataParsed.data));
        }

        default:
          if (process.env.NODE_ENV === 'development') {
            console.log('Unknown window message', messageData);
          }
      }
    }
  }

  static initListener() {
    const dispatch = useDispatch();

    React.useEffect(() => {
      const ec = (msg: MessageEvent<any>) =>
        this.windowEventListener(msg, dispatch);
      window.addEventListener('message', ec);
      return () => {
        window.removeEventListener('message', ec);
      };
    }, [dispatch]);
  }

  private static sendMessage(data: SendMessagePayload) {
    if (this.checkIfInsideWebsite()) {
      window.parent.postMessage(data, '*');
    } else {
      window.postMessage(data, '*');
    }
  }

  static sendInitMessage(data: SendInitMessageData) {
    ParentAppService.sendMessage({
      key: 'tapform-app',
      command: WidgetAppMessageCommand.INIT,
      data,
    });
  }

  static checkIfInsideWebsite() {
    const formSlug = this.checkIfFormLink();
    if (document?.referrer?.includes('https://dashboard.tapform.io')) {
      return false; // This is onboarding
    }
    if (formSlug && formSlug === process.env.REACT_APP_FORM_SLUG_FOR_LANDING) {
      return false;
    }
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static checkIfFormLink(): string | false {
    if (location && location.pathname !== '/') {
      const pathSplit = location.pathname.split('/').slice(1);
      if (pathSplit.length === 1) {
        return pathSplit[0];
      }
    }
    return false;
  }

  static sendExitedMessage(data: SendExitedMessageData) {
    ParentAppService.sendMessage({
      key: 'tapform-app',
      command: WidgetAppMessageCommand.EXITED,
      data,
    });
  }

  static sendCloseMessage(data: SendCloseMessageData) {
    ParentAppService.sendMessage({
      key: 'tapform-app',
      command: WidgetAppMessageCommand.CLOSE,
      data,
    });
  }

  static sendRequestMessage(data: SendRequestMessageData) {
    ParentAppService.sendMessage({
      key: 'tapform-app',
      command: WidgetAppMessageCommand.SEND,
      data,
    });
  }

  static generateUUID() {
    if (window.crypto.randomUUID as any) {
      return window.crypto.randomUUID();
    }
    return uuidv4().toString();
  }
}

export default ParentAppService;
