import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import createStore from './store/store';
import './index.scss';
import './colors.scss';
import ParentAppService from './services/parentApp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const { store } = createStore();

const formFromLink = ParentAppService.checkIfFormLink();
const isFromWebsite = ParentAppService.checkIfInsideWebsite();

if (isFromWebsite || formFromLink) {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  try {
    reportWebVitals();
  } catch (e) {}
} else {
  setTimeout(() => {
    window.location.href = 'https://tapform.io';
  }, 0);
}
